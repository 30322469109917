@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
  font-family: 'Open Sans', sans-serif;
}

/* Remove list styles on ul, ol elements with a list role */
ul,
ol {
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
  background: #010101;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
}

html,
body {
  overflow-x: hidden;
  height: 100%;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

:root {
  --fw-regular: 400;
  --fw-semi-bold: 600;
  --fw-bold: 700;

  --fs-300: 0.8125rem;
  --fs-400: 0.875rem;
  --fs-500: 0.9375rem;
  --fs-600: 1rem;
  --fs-700: 1.875rem;
  --fs-800: 2.5rem;
  --fs-900: 3.5rem;

  --fs-body: var(--fs-400);
  --fs-primary-heading: var(--fs-800);
  --fs-secondary-heading: var(--fs-700);
  --fs-nav: var(--fs-500);
  --fs-button: var(--fs-300);

  --size-100: 0.25rem;
  --size-200: 0.5rem;
  --size-300: 0.75rem;
  --size-400: 1rem;
  --size-500: 1.5rem;
  --size-600: 2rem;
  --size-700: 3rem;
  --size-800: 4rem;
  --size-900: 5rem;
}

/* General Styling */
#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  --max-width: 1680px;
  --container-padding: 1rem;

  width: 100%;
  margin-inline: auto;
  background: black;
}

.button {
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: 10px;
  font-weight: var(--fw-semi-bold);
  line-height: 1;
  color: #020202;
  background: linear-gradient(90deg, #E3A400 0%, #DFBB00 100%);
  box-shadow: 0 1.125em 1em -1em;
}

.cursor-pointer {
  cursor: pointer;
}
