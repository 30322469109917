.primary-header {
  z-index: 5;
  display: grid;
  place-items: center;
  font-family: 'Montserrat';
  position: sticky;
  top: 0;
}

.nav-wrapper {
  background: linear-gradient(180deg, #000000 -64%, #141414 84.5%);
  padding: 0.5em 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  --max-width: 1500px;
  --container-padding: 1rem;
  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
}

/* Desktop Navigation Links */
.nav-links {
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav-button {
  padding: 8px 16px;
  background-color: #E2A700;
  color: black;
  text-decoration: none;
  border-radius: 5px;
}

.logo {
  display: flex;
  align-items: center;
  color: white;
}

.logo p {
  font-family: 'Libre Baskerville', serif;
}

/* Hamburger Menu Icon for Mobile */
.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: #333;
}

/* Dropdown Menu for Mobile */
.dropdown-menu {
  display: none; /* Initially hidden */
  position: absolute;
  background-color: #fff; /* Background color */
  width: 100%;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2); /* Optional shadow */
  z-index: 10;
  left: 0; /* Align to the left of the parent element */
  top: 100%; /* Position directly below the nav bar */
}

.dropdown-menu a {
  display: block; /* Make each link fill the horizontal space */
  color: black; /* Text color */
  padding: 12px 16px; /* Padding for each link */
  text-decoration: none; /* Remove underline */
  border-bottom: 1px solid #ddd; /* Optional border between links */
}

.dropdown-menu a:hover {
  background-color: #f2f2f2; /* Background color on hover */
}

@media (max-width: 768px) {
    .nav-wrapper {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }

    .nav-links {
        display: none; /* Hide the desktop links in mobile view */
    }

    .dropdown-menu.open {
        display: block;
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        background-color: #4A90E2;
        box-shadow: 0 8px 16px rgba(0,0,0,0.2);
        padding: 10px 0;
        border-radius: 0 0 10px 10px;
        transition: all 0.3s ease-in-out;
    }

    .dropdown-menu a {
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        transition: background-color 0.3s;
    }

    .dropdown-menu a:hover {
        background-color: #3D7AB3;
    }

    /* Styles for Hamburger Menu */
    .hamburger {
        display: block;
        cursor: pointer;
    }

    .hamburger span {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px auto;
        transition: all 0.3s;
        background-color: #333;
    }
}
