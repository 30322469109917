footer {
    color: #fff;
    text-align: center;
  }

.footer-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5em 2em;
  background: #010101;
  --max-width: 1500px;
  --container-padding: 1rem;
  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
}

.social-icons-div{
    display: flex;
    width: auto;
    gap: 30px;
}

.social-icons{
  cursor: pointer;
  height: 30px;
  width: 30px;
}

@media (max-width: 50em){
.footer-logo{
  align-self: flex-start;
}

.footer-text{
    display: none;
}
}